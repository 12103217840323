.top-banner {
    background-color: $wrkr-yellow;
    height: 3.8rem;
    display: flex;
    justify-content: space-between;
    p {
        margin: auto;
        font-weight: bold;
        padding: 0 0.5rem;
    }
}