amplify-authenticator {
  --container-height: auto;
  --border-radius: 4px;
  --amplify-primary-color: #{$black};
  --amplify-primary-tint: #{$link-color};
  --amplify-primary-contrast: #{$white};
  --amplify-font-family: 'Roboto';
}

.registration__container {
  background-color: $grey-10;
  min-height: 100vh;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  flex: 1;
  // height: 100vh;
  max-width: 500px;
  margin: 0 auto;
}

.login__logo {
  height: 40px;
  width: 150px;
  margin: 2.5rem 3rem 1rem;
  background-image: url(../../assets/images/logo_wrkr.svg);
  background-repeat: no-repeat;
  background-size: contain;
  // background-position: center;
}

.login__container {
  // height: auto;
  // width: 90%;
  // max-width: 500px;
  border-radius: 4px;
  padding: 2.5rem 2rem 2rem;
  background-color: $white;
  margin: 1rem 1rem 2rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

  h1 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    letter-spacing: -0.5px;
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }

  label {
    font-weight: bold;
  }

  .label {
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    color: $grey;
  }

  a {
    color: $link-color;
    cursor: pointer;
  }

  ol {
    margin-left: 1rem;
    list-style-type: decimal;
    li {
      padding-left: 0.5rem;
      padding-bottom: 1rem;
      line-height: 1.5rem;
    }
  }
}

.form-fields-inline {
  display: flex;
  justify-content: space-between;
  .form-group {
    width: 100%;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.form-flag {
  position: relative;
  .flag {
    position: absolute;
    width: 75px;
    height: 45px;
    top: 0;
    left: 0px;
    background-color: $grey-10;
    display: flex;
    justify-content: center;
    svg {
      width: 26px;
    }
    span {
      margin-left: 5px;
      margin-top: 14px;
      color: $grey;
    }
  }
  input {
    padding-left: 80px;
  }
}

.pin-group {
  margin: 0 -0.5rem 1rem;
  .pin-input {
    input {
      width: 100% !important;
      margin: 0 0.3rem;
      font-size: 1.2rem;
      @media (min-width: $bp-sm) {
        font-size: 2rem;
        margin: 0 0.5rem;
      }
    }
  }
}

.send-code {
  &__container {
    background-color: $grey-10;
    border-radius: 4px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__mobile {
    padding-left: 1rem;
    font-size: 22px;
  }
}

.collapse-group {
  display: flex;
  align-items: center;
  svg {
    height: 14px;
  }
}

.qr-code {
  border: 1px solid $grey-30;
  padding: 2rem;
  border-radius: 4px;
  // margin: 1.5rem;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.divider {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.eye {
  position: absolute;
  right: 0;
  top: 0;
  div {
    border: none;
    background: none;
    padding: 0.8rem;
    display: flex;
    svg {
      width: 1.2rem;
      height: 1.2rem;
      color: $grey-80;
    }
  }
  &.eye-login {
    margin-top: 1.6rem;
  }
}

p.error-message {
  color: $red;
  margin-top: 1rem;
}
