.spinner {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 20px auto;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 60%;
  height: 60%;
  background-color: $primary-color;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
