.form-fields-inline__date-range {
    display: flex;
    justify-content: space-between;
    margin-bottom: -2rem;
    .form-group {
      width: 100%;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
    .form-group {
        label {
            font-size: 0.8rem;
            font-weight: bold;
            color: $black;
        }
        input {
          @include border-radius;
          width: 100%;
          padding: 0.5rem;
          border: 1px solid $grey-50;
          background: url("../../assets/images/calendar.svg") no-repeat;
          background-position: 95% 50%;
          
      
          &:focus,
          &:active {
            outline: 0;
            border: 1px solid $blue;
            box-shadow: 0 0 0 1px $blue;
          }
        }
        .is-invalid {
          border: 1px solid $red;
        }
      
    }
  }