.DayPickerInput {
  position: relative;
  width: 100%;

  input {
    @include border-radius;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $grey-50;

    &:focus,
    &:active {
      outline: 0;
      border: 1px solid $blue;
      box-shadow: 0 0 0 1px $blue;
    }
  }

  &:after {
    @include image-size(1.25rem);
    content: "";
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    background-image: url("../../assets/images/calendar.svg");
  }
}

.DayPicker {
  width: 100%;
  font-size: 1rem;
}

.DayPickerInput-Overlay {
  top: 1px;
  width: 100%;
}

.DayPicker-wrapper {
  width: 100%;
}

.DayRangePicker
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
