/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
  box-shadow: none;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

dl,
dt,
dd {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;

  &:not(.button):hover {
    text-decoration: underline;
    text-decoration-color: $blue;
  }

  &.link {
    color: $link-color;
    cursor: pointer;
    svg {
      width: 12px;
      vertical-align: middle;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &.arrow {
    background-image: url(../../assets/images/icons/icon_chevron-right.svg);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 1rem;
  }
}

hr {
  height: 1px;
  margin: 1.5rem auto;
  background: $grey-30;
  border: 0;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  // vertical-align: middle;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[hidden] {
  display: none !important;
}

/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0%;
    -webkit-appearance: none;
}
