.add-employee {
  .flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -1rem;

    > * {
      flex: 1;
      margin-bottom: 1rem;
      padding: 0 1rem 1rem;
    }

    .aside-container {
      display: none;
    }

    @media (min-width: $bp-lg) {
      flex-direction: row;

      .main-container {
        flex: 3;
      }

      .aside-container {
        display: block;
        flex: 2;
        border-left: 1px solid $grey-30;
      }
    }

    @media (min-width: $bp-xl) {
      .main-container {
        flex: 3;
      }

      .aside-container {
        flex: 1;
      }
    }
  }

  form {
    max-width: 25rem;
  }

  fieldset {
    margin-top: 2rem;

    legend {
      margin-bottom: 1rem;
    }
  }

  .add-employee__links {
    margin-left: -1rem;

    a {
      display: block;
      padding: 0.75rem 1rem;

      &.is-active {
        position: relative;
        font-weight: 700;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0.25rem;
          height: 100%;
          background-color: $yellow;
        }
      }
    }
  }
}
