/*---------- PLACEHOLDERS----------*/

// rectangle placeholder boxes
.placeholder {
  display: inline-block;
  min-height: 1.2em;
  vertical-align: middle;
  cursor: wait;
  background-color: $grey;
  opacity: 0.5;
  width: 70%;
  animation: fading 1.5s infinite;
  border-radius: 4px;
}

// animation
@keyframes fading {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}

.loader__placeholder {
  // table placeholder
  .table-wrapper {
    box-shadow: none;
    .table tr:hover {
      background-color: transparent !important;
    }
  }
  // LHN placeholder
  .sidebar {
    .placeholder {
      min-height: 1.4rem;
      width: 80%;
    }
  }
}
