.tag {
  position: relative;
  display: inline-block;
  padding-left: 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $default;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: tint($default, 50%);
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.25rem;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: $default;
  }
}

.tag--new {
  color: $new;

  &:before {
    background: tint($new, 50%);
  }

  &:after {
    background: $new;
  }
}

.tag--success {
  color: $success;

  &:before {
    background: tint($success, 50%);
  }

  &:after {
    background: $success;
  }
}

.tag--warning {
  color: $warning;

  &:before {
    background: tint($warning, 50%);
  }

  &:after {
    background: $warning;
  }
}

.tag--alert {
  color: $alert;

  &:before {
    background: tint($alert, 50%);
  }

  &:after {
    background: $alert;
  }
}
