.receipt {
  @include border-radius;
  max-width: 38rem;
  margin: 0 auto;
  border: 1px solid $grey-50;
  text-align: center;

  dd {
    text-align: left;
  }
}

.receipt__highlight {
  @include border-radius;
  margin: 1rem;
  padding: 1rem;
  background-color: rgba($turquoise, 0.5);
  text-align: center;
}
