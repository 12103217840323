.table-wrapper {
  border: $border;
  @include border-radius;
  @include box-shadow;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1;

  @media (min-width: $bp-lg) {
    table-layout: fixed;
  }

  .col-xs {
    width: 3rem;
  }

  th {
    padding: 1.25rem 1rem;
    border-bottom: $border;
    vertical-align: middle;
    text-align: left;
    color: $grey;
    line-height: 1.1rem;
    border-right: $border;
    &:last-child {
      border-right: none;
    }

    &.is-alignRight {
      text-align: right;
    }

    // &:first-of-type {
    //   border-left: $border;
    // }

    // &:last-of-type {
    //   border-right: $border;
    // }
  }

  tbody {
    tr:hover,
    tr:focus-within {
      background-color: $highlight;
      transition: ease-in-out 0.2s;
      .is-delete {
        button {
          transition: ease-in-out 0.2s;
          cursor: pointer;
          opacity: 100;
        }
      }
    }

    .word-break {
      word-break: break-all;
    }
  }

  td {
    padding: 1rem;
    border-bottom: $border-light;
    border-right: $border;
    &:last-child {
      border-right: none;
    }

    &.is-capitalized {
      text-transform: capitalize;
    }

    &.is-alignRight {
      text-align: right;
    }

    &.is-delete {
      button {
        opacity: 0;
        svg {
          color: $black;
        }
      }
    }

    small {
      display: block;
      margin-top: 0.25rem;
      font-weight: 300;
      line-height: 1;
      color: red;
      word-break: break-all;
    }

    &.truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      display: block;
    }
  }

  tfoot {
    td {
      padding: 1rem;
      // background-color: $grey-10;
      font-weight: bold;
    }
  }
}

//ROLLOVERS SPECIFIC
.table-rollovers {
  // tr {
  //   &.action-req td {
  //     background-color: rgba(162, 233, 220, 0.15);
  //     // background-color: rgba(247, 222, 52, 0.1);
  //   }
  // }

  tr td {
    padding: 0;

    a {
      padding: 1rem;
      display: block;
      line-height: 1.1rem;

      &:hover {
        text-decoration: none;
      }

      &.link:hover {
        text-decoration: underline;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  thead tr {
    th.col-fund {
      &:nth-child(2) {
        border-right: none;
      }
    }
    th.col-arrow {
      border-right: none;
    }
  }

  tbody tr {
    position: relative;
    .row-arrow a {
      background-image: url(../../assets/images/icons/icon_chevron-right-lg.svg);
      background-repeat: no-repeat;
      // background-position: calc(100% - 20px) center;
      background-position: center center;
      transition: all 0.2s ease-out;

      &.disabled-link {
        background: none;
      }
    }

    &:hover {
      .row-arrow a {
        background-position: calc(100% - 10px) center;
      }
    }
  }

  .col-fund {
    width: 150px;

    @media (min-width: $bp-xl) {
      width: auto;
    }
  }

  .col-arrow {
    width: 54px;
    padding: 0;
    text-align: center;
  }

  .col-date {
    @media (min-width: $bp-xl) {
      width: 120px;
    }
    @media (min-width: $bp-xxl) {
      width: 120px;
    }
  }

  .col-status {
    @media (min-width: $bp-xl) {
      width: 145px;
    }
  }

  // .col-payer {
  //   @media (min-width: $bp-xl) {
  //     width: 300px;
  //   }
  // }

  .col-action {
    @media (min-width: $bp-xxl) {
      width: 160px;
    }
  }

  .txt-action {
    font-weight: bold;
    font-size: 13px;
    display: inline-block;
  }

  .icon-alert svg {
    color: $alert;
    width: 20px;
    height: 20px;
    vertical-align: middle;

    margin: 0.5rem 0 0.5rem 0.5rem;
  }
}

.table--member {
  border: hidden;
  .col-lg {
    width: 15rem;
  }
  .button--link {
    text-align: left;
  }
  .note {
    color: $alert;
    margin-bottom: 4px;
  }

  td,
  th {
    border: 1px solid $grey-15;
  }

  tr {
    // border-bottom: 1px solid $grey-30;
    &.table-header td {
      border-top: 1.1px solid $grey-30;
    }
  }

  // .col-date {
  //   @media (min-width: $bp-xl) {
  //     width: 115px;
  //   }
  //   @media (min-width: $bp-xxl) {
  //     width: auto;
  //   }
  // }
  // .col-tfn {
  //   @media (min-width: $bp-xl) {
  //     width: 115px;
  //   }
  //   @media (min-width: $bp-xxl) {
  //     width: auto;
  //   }
  // }
}
