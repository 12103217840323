main {
  .wizard {
    border-bottom: $border;
    padding-top: 1rem;
    margin-bottom: 3rem;

    .wizard__group {
      margin: 0 3.5rem;

      ul {
        display: flex;
        font-size: 14px;
        li {
          padding-left: 2.5rem;
          opacity: 0.5;
          background-image: url(../../assets/images/icons/icon_chevron-right-black.svg);
          background-repeat: no-repeat;
          background-position: left 14px center;
          &:first-child {
            background-image: none;
            padding-left: 0;
          }
          &.active {
            font-weight: bold;
            a:after {
              content: '';
              position: absolute;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;

              border-bottom: 4px solid $wrkr-yellow;
            }
          }
          &.active,
          &.complete {
            color: $black;
            opacity: 1;
            a:hover:after {
              content: '';
              position: absolute;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;
              // background-color: red;
              border-bottom: 4px solid $wrkr-yellow;
            }
          }
          &.disabled {
            a:hover {
              cursor: default;
            }
          }

          a {
            position: relative;
            display: block;
            padding-top: 1rem;
            padding-bottom: 1rem;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  // .main__no-sidebar {
  // .account-setup-breadcrumbs {
  //   font-family: Rubik;
  //   font-size: 20px;
  //   font-weight: 500;
  //   line-height: 30px;
  //   letter-spacing: 0em;
  //   text-align: left;
  //   margin-top: 12px;
  // }

  // .breadcrumbs {
  //   border-bottom: 1px solid #33333326;
  //   padding-bottom: 4px;
  //   .last-breadcrumb {
  //     border-bottom: 4px solid #f7dd38;
  //   }
  // }
  // }
  // .dismiss-container {
  //   text-align: right;
  // }
}

.account-setup__container {
  margin-top: 2rem;
  padding-bottom: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    margin-bottom: 1rem;
    font-size: 40px;
  }

  .h1--inpanel {
    font-size: 34px;
  }

  h2 {
    font-family: $display-font-family-regular;
    line-height: 2.5rem;
    font-size: 28px;
    font-weight: 400;
  }

  .subhead1 {
    font-family: $display-font-family;
    font-size: 20px;
    font-weight: 500;
  }

  .account-setup__section {
    @include border-radius;
    @include box-shadow;
    max-width: 40rem;
    padding: 2.5rem;
    border: 1px solid $grey-50;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  hr {
    margin: 1rem 0;
  }

  dl {
    div {
      border: none;
      dt {
        text-align: left;
        width: 100px;
        padding: 4px;
        font-weight: bold;
      }
      dd {
        padding: 4px;
      }
    }
  }

  .button-group {
    .next-button {
      width: 200px;
    }
  }

  .button--new-page {
    border: 1px solid $black;
    display: block;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: $body-font-family;
    background-image: url(../../assets/images/icons/icon_chevron-right-lg.svg);
    background-repeat: no-repeat;
    background-position: right 20px center;
    transition: background-position 0.5s ease-in-out;
    &:hover {
      background-position: right 10px center;
    }
  }

  .credit-card {
    display: flex;
    align-items: center;
    width: 100%;
    svg {
      height: 20px;
      margin-left: 1rem;
    }
  }

  .update-ato-footer {
    margin-top: 2rem;
  }
}

//account setup confirmation

.ssid {
  @include border-radius;
  padding: 1rem;
  background-color: $grey-10;
}

.tick {
  background-color: $success;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  padding: 1rem;
  margin: 0 auto;
  margin-bottom: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.print {
  float: right;
  font-size: 14px;
}

.heading-label {
  font-weight: 200;
}
