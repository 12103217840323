button {
  cursor: pointer;
}

.button {
  padding: 0.7rem 1.5rem;
  background: transparent;
  border: 1px solid transparent;
  font-family: $display-font-family;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  transition: all 0.15s ease;

  &:focus,
  &:active {
    outline: 0;
    border-color: $grey;
    box-shadow: 0 0 0 1px $grey;
  }

  // &:hover {
  //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  //   background-color: $grey-10;
  // }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    // border: none;
    &:hover {
      box-shadow: none;
      svg {
        transform: none;
      }
    }
  }
}

.button--icon {
  display: inline-flex;
  align-self: center;
  align-items: center;
  // padding-right: 2rem;
  padding: 0;
  background: none;
  border: 0;
  border-radius: 0;

  &:focus,
  &:hover,
  &:active {
    border: 0;
  }
}

.button--primary {
  @include border-radius(2rem);
  // padding: 0.5rem 1.25rem;
  // padding: 1rem;
  background-color: $primary-color;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: 0;
    background-color: tint($primary-color, 10%);
    @include drop-shadow;
  }
}

// Transparent background
.button--transparent {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.button--secondary {
  @include border-radius(2rem);
  padding: 0.5rem 1.25rem;
  background-color: $turquoise;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: 0;
    background-color: tint($turquoise, 10%);
    @include drop-shadow;
  }
}

.button-tertiary {
  &:hover {
    text-decoration: underline;
  }
}

.button--success {
  padding: 0.5rem 1.25rem;
  background-color: $success;
  color: #fff;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: 0;
    background-color: tint($success, 10%);
  }
}

.button--outline {
  @include border-radius(2rem);
  // padding: 0.5rem 1.25rem;
  background-color: $white;
  border: 1px solid $black;
  color: $black;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border: 1px solid;
    border-color: shade($black, 10%);
    color: shade($black, 10%);
    @include drop-shadow;
  }
}

.button--outline-primary {
  @include border-radius(2rem);
  border-color: $yellow;
  color: $yellow;

  &:focus,
  &:hover,
  &:active {
    border-color: shade($yellow, 10%);
    color: shade($yellow, 10%);
  }
}

.button--outline-secondary {
  @include border-radius(2rem);
  border-color: $turquoise;
  color: $turquoise;

  &:focus,
  &:hover,
  &:active {
    border-color: shade($turquoise, 10%);
    color: shade($turquoise, 10%);
  }
}

.button--cta {
  border-radius: 1.25rem;
}

.button--lg {
  padding: 1rem 1.5rem;
  &.done {
    width: 12rem;
  }
}
.button--sm {
  padding: 0.7rem 1.5rem;
  // font-family: $body-font-family;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.button--xs {
  font-family: $body-font-family;
  font-size: 14px;
  padding: 0.5rem 1rem;
  // font-weight: bold;
}

.button--icon-lg {
  padding: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.button--icon-xs {
  svg {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  }
}

.button--icon-before,
.button--icon-after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button--icon-before__icon,
.button--icon-afer__icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  color: currentColor;
}

.button--icon-before__icon {
  margin-right: 0.5rem;
}

.button--icon-after__icon {
  margin-left: 0.5rem;
}

.button--underline {
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  border: 0;
  border-radius: 0;

  &:focus,
  &:hover,
  &:active {
    color: $grey;
  }
}

//this will look exactly like a text link
.button--link {
  font-family: $body-font-family;
  color: $link-color;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
}

.button--back,
.button--next {
  @include border-radius(2rem);
  position: relative;
  width: 12rem;
  padding: 1rem;

  svg {
    position: absolute;
    transition: transform 0.2s ease;
    width: 1.3rem;
    height: 1.3rem;
    margin-top: -2px;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.button--back {
  svg {
    left: 2rem;
  }

  &:focus,
  &:hover,
  &:active {
    svg {
      transform: translateX(-0.5rem);
    }
  }
}

.button--next {
  background-color: $yellow;
  border-color: $yellow;

  svg {
    right: 2rem;
  }

  &:focus,
  &:hover,
  &:active {
    border-color: shade($yellow, 10%);

    svg {
      transform: translateX(0.5rem);
    }
  }
}

.button-group {
  display: flex;
  margin-bottom: 1rem;

  .button + .button {
    margin-left: 1rem;
  }
}

.button-switch {
  @include border-radius(2rem);
  display: flex;
  justify-content: space-around;
  border: 1px solid $black;

  .button {
    flex: 1;
    // padding: 0.75rem 1.25rem;
    padding: 1rem 1.25rem;
    border-radius: 2rem 0 0 2rem;
    font-weight: normal;

    &.is-selected {
      background-color: rgba(162, 233, 220, 0.5);
    }
  }

  .button + .button {
    border-left: 1px solid $black;
    border-radius: 0 2rem 2rem 0;
  }
}

.button--unstyled {
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.button--link {
  @extend .link;
  @extend .button--unstyled;
}

.button--help {
  vertical-align: text-bottom;
}

.button--highlight {
  background-color: $wrkr-yellow;
}

.button--black {
  @include border-radius(2rem);
  background-color: $black;
  color: $white;
  font-size: 0.9rem;
}

.button--full-width {
  width: 100%;
}

.button--done {
  width: 10rem;
}


.button-switch {
  @include border-radius(2rem);
  display: flex;
  justify-content: space-around;
  border: 1px solid $black;

  .button {
    flex: 1;
    // padding: 0.75rem 1.25rem;
    padding: 1rem 1.25rem;
    border-radius: 2rem 0 0 2rem;
    font-weight: normal;

    &.is-selected {
      background-color: rgba(162, 233, 220, 0.5);
    }
  }

  .button + .button {
    border-left: 1px solid $black;
    border-radius: 0 2rem 2rem 0;
  }
}