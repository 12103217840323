.pill {
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  @include border-radius(3px);
  font-size: 0.75rem;
  font-weight: 700;
  // letter-spacing: 1px;
  // text-transform: lowercase;
  color: $black;
  text-align: center;
  line-height: 0.75rem;

  @media (min-width: $bp-xl) {
    width: 7rem;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.pill--new {
  background: $new;
  color: #fff;
}

.pill--success {
  background: $success;
}

.pill--warning {
  background: $warning;
}

.pill--error {
  background: $alert;
  color: #fff;
}

.pill--initiate {
  background: $default;
}

.pill--request {
  background: $info;
  &.grey {
    background: $white;
  }
}
