.terms-and-conditions-modal {
  width: 512px;

  .terms-and-conditions {
    height: 245px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
}



.terms-and-conditions-content {
  h1 {
    font-size: 22px !important;
  }

  h2 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  ul {
    line-height: 1.5rem;

    li {
      margin-bottom: 1rem;
    }
  }

  ol {
    list-style-type: lower-alpha;

    li {
      margin-left: 2rem;
    }

    ol {
      list-style-type: lower-roman;
    }
  }
}