.rollovers-detail {
  .participating-funds {
    display: flex;
    gap: 1rem;
    margin-bottom: 2.5rem;
    @media (min-width: $bp-xl) {
      gap: 2rem;
    }

    .fund {
      width: 40%;
      background-color: $grey-10;
      padding: 1.5rem;
      border-radius: 1rem;
      @media (min-width: $bp-xl) {
        padding: 2rem;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
      }

      dl {
        div {
          border: none;

          dt,
          dd {
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 0.4rem;
            min-width: auto;
            width: auto;
          }
          dt {
            font-size: 14px;
          }

          dd {
            font-size: 15px;
          }
        }
      }
    }

    .arrow {
      h2 {
        margin: 0;

        min-width: 6rem;
      }
      text-align: center;
      margin: auto;
    }
  }

  .member-status {
    font-weight: bold;
    background-image: url(../../assets/images/icons/icon_circle-pending.svg);
    background-repeat: no-repeat;
    background-position: center left;
    padding: 0.5rem 0.5rem 0.5rem 1.75rem;
    text-align: left;

    &--pending {
      background-image: url(../../assets/images/icons/icon_circle-pending.svg);
    }

    &--error {
      background-image: url(../../assets/images/icons/icon_circle-error.svg);
    }

    &--complete {
      background-image: url(../../assets/images/icons/icon_circle-complete.svg);
    }
  }

  .content-sidebar {
    .payment-container {
      padding: 1.5rem;
      border-radius: 1rem;
      border: $border;
      text-align: center;

      h2 {
        margin-bottom: 0.8rem;
      }
    }
    ul {
      margin-top: 1rem;
      li {
        border-bottom: $border;
        padding: 1rem;

        svg {
          margin-right: 0.5rem;
          vertical-align: top;
          width: 1.2rem;
          height: 1.2rem;
          color: $black;
        }
      }
    }
  }
}

.subtitle {
  font-size: 12px;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: $body-font-family;
  color: $grey;
  // font-weight: bold;
}

.well {
  background-color: $grey-10;
  padding: 1rem;
  @include border-radius;
  border: $border;

  table {
    font-size: 14px;
    line-height: 1.15rem;
  }
  tbody th,
  tbody td {
    background-color: $white;
  }

  ul,
  .checkbox {
    margin-bottom: 0;
  }
}
.action--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $turquoise-bg;
  box-shadow: inset 0px 10px 5px -10px $grey-50;
  p {
    font-size: 15px;
    margin-bottom: 0;
  }
}
