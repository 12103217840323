.modal {
  @include border-radius;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 1.5rem;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: auto;
  background: white;
  box-shadow: 0px 2px 6px $grey-20;
  overflow: hidden;
  outline: none;
  text-align: left;
  word-break: break-word;
  z-index: 2;

  ul,
  ol {
    margin-left: 1.5rem;
    list-style: initial;
    line-height: 1.5rem;
    margin-bottom: 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }

  ol {
    list-style: decimal;
  }
  &.info {
    width: 530px;
  }
}

.modal--center {
  padding: 1.5rem 2.5rem;
  text-align: center;
}

.content-container {
  @include border-radius;
  max-height: 50vh;
  padding: 1rem;
  border: 1px solid $grey-30;
  overflow: auto;
}

.modal--lg {
  width: 38rem;
}

.modal--action {
  padding: 0;

  .modal__header {
    padding: 1.25rem 1.5rem;
  }

  .modal__body {
    padding: 2.5rem 1.5rem;
    border-top: 1px solid $grey-10;
    border-bottom: 1px solid $grey-10;
  }

  .modal__footer {
    padding: 1rem 1.5rem;

    .button {
      margin-bottom: 0;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.4);
}


.modal__header, .modal__header_narrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  button {
    cursor: pointer;
  }
}

.modal__body {
  //border-top: 1px solid $border; //why isn't this working? - CL
  .form {
    max-width: none;
  }
}

// Transations
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.15s;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  transform: translate(-50%, calc(-50% - 50px));
  transition: opacity 0.15s, transform 0.3s;
  padding: 0;
  .modal__header {
    padding: 1rem 1rem 1rem 1.5rem;
    border-bottom: $border;
  }
  .modal__header_narrow {
    padding: 0.3rem 1rem 0.2rem 1.5rem;
    border-bottom: $border;
  }
  .modal__body {
    padding: 1rem 1.5rem;
  }

  .modal__footer {
    padding: 1rem 1rem 1rem 1.5rem;
    border-top: $border;
    &.button-group {
      margin-bottom: 0;
    }
  }
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translate(-50%, calc(-50% - 50px));
}

// Prevent body from scrolling when modal is open
.ReactModal__Body--open {
  overflow: hidden;
}
