.step-indicators {
  li {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 100%;
    background-color: $grey-30;

    &.is-active {
      background-color: $black;
    }
  }

  li + li {
    margin-left: 0.75rem;
  }
}
