ul.list {
  margin-left: 1.5rem;
  list-style: initial;
  line-height: 1.5rem;
  margin-bottom: 1rem;

  li {
    margin-bottom: 0.5rem;
  }
}
//----- ordered list -------//

.ordered-list {
  counter-reset: step;

  > li {
    counter-increment: step;
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 3.5rem;
    border-top: 1px solid $grey-30;

    &:before {
      content: counter(step);
      position: absolute;
      left: 0;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-color: $turquoise;
      text-align: center;
      line-height: 2rem;
      border-radius: 50%;
      color: $black;
      transition: all 0.2s ease-in-out;
    }

    h3 {
      line-height: 2rem;
    }

    ul {
      margin-left: 1rem;
      list-style: initial;

      li {
        margin-bottom: 1rem;
      }
    }
  }
}

//----- dl / dd / dt styles -------//

dl {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $grey-20;

    &:last-child {
      border: none;
    }
  }

  dt {
    font-size: 14px;
    color: $grey;
    // font-weight: bold;
    width: 40%;
    text-align: right;
    padding: 0.8rem;

    color: $grey;
    line-height: 1.2rem;
    text-align: right;
  }

  dd {
    padding: 0.8rem;
    line-height: 1.3rem;
    font-size: 15px;
    width: 60%;
  }
}

//----- custom number styles -------//
.list-item {
  display: flex;
  align-content: space-between;
  margin-bottom: 3rem;
}

.list-item__number {
  background-color: $turquoise;
  padding: 0.5rem 0.7rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-weight: bold;
  margin-right: 1rem;
}

.definition-list {
  h4 {
    margin-bottom: 4px;
  }
}
