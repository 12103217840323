.is-bold {
  font-weight: 700 !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-capitalize {
  text-transform: capitalize !important;
}

.text-align-center {
  text-align: center;
}

.subtxt {
  font-size: 12px;
  color: $grey;
  padding-top: 4px;
  // word-break: break-all;
  display: block;
  &--inline {
    margin-left: 4px;
    display: inline-block;
  }
}

.is-hidden {
  display: none;
}

.error {
  color: #e11f30;
  margin-top: 0.5rem;
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
