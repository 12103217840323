ul.password-requirements-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.875rem;
  justify-content: left;
  margin-top: 1rem;

  li {
    padding-left: 1.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    flex-basis: 50%;
    background-repeat: no-repeat;
    background-position: center left;
    color: $grey;
    background-size: 16px;
    background-image: url(../../assets/images/icons/icon_circle-complete-grey.svg);

    &.is-error {
      color: $red;
      background-image: url(../../assets/images/icons/icon_circle-error.svg);
    }

    &.is-ok {
      background-image: url(../../assets/images/icons/icon_circle-complete.svg);
    }
  }
}
