.timeline {
  li {
    display: flex;

    &:last-of-type .timeline-line:after {
      content: none;
    }
  }

  .timeline-line {
    position: relative;
    margin-right: 1rem;

    &:after {
      position: absolute;
      top: 2.5rem;
      left: 50%;
      content: "";
      width: 2px;
      height: calc(100% - 3rem);
      background-color: $grey-30;
      z-index: -1;
    }
  }

  .timeline-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;

    &.is-success {
      background-color: $success;
      svg {
        color: $black;
      }
    }

    &.is-pending {
      background-color: $pending;
      svg {
        color: $black;
      }
    }

    &.is-alert {
      background-color: $alert;
      svg {
        color: $white;
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .timeline-details {
    margin-bottom: 2rem;

    p {
      margin-bottom: 0.5rem;
      max-width: 80ch;
    }
  }

  .h3 {
    margin-bottom: 0.5rem;
  }

  time {
    color: $grey;
  }

  .timeline-action {
    padding: 1rem;
    background-color: $grey-10;
  }
}
