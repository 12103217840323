@media print {
  // Force printing of background colors and images in browsers
  body {
    color-adjust: exact;
  }

  .d-print-none {
    display: none;
  }
}
