.reports__container {
    margin-top: 2rem;
    padding-bottom: 1rem;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
.reports {
    @include border-radius;
    @include box-shadow;
    width: 65%;
    padding: 2.5rem;
    border: 1px solid $grey-50;
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }