$header-height: 4rem;

.header {
  width: 100%;
  height: $header-height;
  background: #fff;
  //border-bottom: 2px solid $grey-30;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.header__inner {
  height: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__logo {
  position: relative;
  margin-right: 2rem;
  padding-right: 1.8rem;

  svg {
    height: 24px;
    width: auto;
  }
}

.header__logo-small {
  position: relative;
  margin-right: 2rem;
  margin-top: 0.1rem;
  padding-right: 2rem;

  svg {
    height: 25px;
    width: auto;
  }
}

.header__nav {
  font-weight: bold;

  a {
    position: relative;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &:hover {
      text-decoration: none;
      &:after {
        background: $grey-50;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 1px;
      width: 100%;
      height: 4px;
      background: transparent;
    }

    &.is-active:after {
      background: $primary-color;
    }
  }
}

.user-dropdown {
  // border-bottom: 3px solid red;

  button {
    font-weight: normal;
    font-family: $body-font-family;
    color: $black;
  }
  & > button {
    font-weight: bold;
    padding: 1.5rem 0;
    position: relative;
    svg {
      width: 12px;
      color: $grey;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background: transparent;
    }
    &:hover:after {
      // border-bottom: 3px solid green;
      background: $grey-50;
    }
  }

  svg {
    width: 20px;
  }
}
