.dashboard {
  article {
    .banner {
      padding-bottom: 1.5rem;
    }
    h1 {
      font-size: 24px;

      display: flex;
      svg {
        margin-right: 8px;
      }
    }
  }
}
