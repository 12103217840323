.panel {
  @include border-radius;
  padding: 2.5rem;
  border: 1px solid $grey-50;

  & + & {
    margin-top: 2rem;
  }

  &__footer {
    background-color: $grey-10;
    padding: 2rem 2.5rem;
    margin: 2rem -2.5rem -2.5rem;
  }

  &--narrow {
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
