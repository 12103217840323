.tooltip-icon {
  margin-left: 0.8rem;
  svg {
    width: 20px;
    height: 20px;
    color: $grey-blue;
  }
}

.tooltip-popup {
  font-size: 14px;
  padding: 1rem;
  p {
    font-size: 14px;
    margin-bottom: 2px;
  }
  ul {
    li {
      line-height: 1.2rem;
    }
    ul {
      margin-top: 0.5rem;
      margin-left: 1rem;
      list-style-type: circle;

      margin-bottom: 1rem;
    }
  }
}
