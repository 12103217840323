.slide-pane__overlay--custom {
  z-index: 1;
}

.slide-pane--custom {
  max-width: 26rem;
}

.slide-pane--custom {
  .slide-pane__header {
    flex-direction: row-reverse;
    background: none;
    margin: 0 1rem;
    border-bottom: 0;
  }

  .slide-pane__close {
    margin: 0;
    opacity: 1;
    padding: 0;

    svg {
      width: 2rem;
      height: 2rem;
      color: $grey;
    }
  }

  .slide-pane__title-wrapper {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .slide-pane__title {
    @extend .h3;
    margin-bottom: 1rem;
  }

  .slide-pane__content {
    // padding: 0;
    margin-top: -1rem;
    padding-top: 1rem;
  }

  .accordion__panel {
    border: 0;
  }

  &.slide-pane--member-details {
    max-width: 32rem;
    .subheading {
      border-bottom: $border;
      padding-bottom: 1rem;
      margin-bottom: 0;
      font-size: 15px;
    }
  }

  .history--container {
    ul {
      li {
        border-bottom: $border;
        text-align: left;
        padding: 1rem 0;
        font-size: 15px;
      }
    }

    p.rollover-type {
      font-size: 15px;
      margin-bottom: 0;
    }
    .status--icon {
      margin-left: 4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .history--date {
      color: $grey;
      font-size: 14px;
      margin-top: 4px;
    }

    .more-details--btn {
      margin: 0.8rem 0 0;
      color: $grey;
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
        // stroke-width: 1px;
        stroke: $grey;
        margin-left: 2px;
        // margin-top: 6px;
      }
    }

    .more-details--container {
      background-color: $grey-10;
      padding: 1rem;
      margin-top: 0.8rem;
      .conversation-id {
        font-size: smaller;
        color: #5f6062;
        line-height: 150%;
      }
      .success--container {
        margin-top: 1rem;
        border-top: $border;
      }
      .error--container {
        padding-top: 1rem;
        border-bottom: $border;
        padding-bottom: 1rem;
        &:first-child {
          margin-top: 1rem;
          border-top: $border;
        }
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0.5rem;
        }
        .error__title {
          font-size: 15px;
          line-height: 1.3rem;
          margin-bottom: 4px;
        }
        .error__content {
          font-size: 14px;
          margin-bottom: 0;
          word-break: break-word;
        }
      }

      dl {
        div {
          border-bottom: 0;
        }
        dt {
          width: 30%;
          padding: 5px;
        }
        dd {
          width: 70%;
          padding: 5px;
        }
      }
    }
  }
}
