$colors: (
  "success": $success-alt,
  "info": $info,
  "warning": $warning,
  "pending": $pending,
  "alert": $alert,
);

@each $status, $color in $colors {
  .is-#{$status} {
    color: $color !important;
  }
}
