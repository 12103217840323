// Fade transitions
.Fade-enter {
  opacity: 0;
  transform: translateX(-1rem);
}
.Fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-in;
}
.Fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.Fade-exit-active {
  opacity: 0;
  transform: translateX(1rem);
  transition: all 0.2s ease-in;
}
