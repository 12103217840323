@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Regular.woff') format('woff');
  font-display: fallback;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('../../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Bold.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik-Light';
  font-weight: 200;
  src: url('../../assets/fonts/Rubik-Light.woff') format('woff');

  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url('../../assets/fonts/Rubik-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Rubik-Regular.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik-Medium';
  font-weight: 500;
  src: url('../../assets/fonts/Rubik-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Rubik-Medium.woff') format('woff');
  font-display: fallback;
}

$body-font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

$display-font-family: 'Rubik-Medium', sans-serif;
$display-font-family-regular: 'Rubik', sans-serif;

body {
  font-size: 1rem;
  font-family: $body-font-family;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-family: $display-font-family;
  font-size: 2.125rem;
  line-height: 1.2;
  font-weight: 500;
}

h2,
.h2 {
  font-family: $display-font-family;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

h3,
.h3 {
  font-family: $display-font-family-regular;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
}

h4,
.h4 {
  font-family: $display-font-family;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

p,
.p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.p--sm {
  font-size: 0.875rem;
  line-height: 1.5;
  color: $grey;
}

.p--lg {
  font-size: 1.2rem;
}

.title {
  font-size: 56px;
  line-height: 130%;
}

small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.longform {
  font-family: $display-font-family-regular;
  font-size: 22px;
  // font-weight: 200;
  line-height: 1.5;
  strong {
    font-family: $display-font-family-regular;
  }
}

.subhead1 {
  font-family: $display-font-family;
  font-size: 20px;
  font-weight: 500;
}
