.tippy-box {
  .tippy-content {
    padding: 0;

    ul {
      hr {
        margin: 0;
      }

      li {
        span.static-menu-item {
          padding: 0.75rem 1rem;
          display: block;
          font-weight: bold;
        }

        button,
        a {
          padding: 0.75rem 1rem;

          &:hover {
            background-color: $highlight;
            text-decoration: none;
          }
        }
      }
    }
  }
}
