.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-5 {
  width: 1.25rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.mw-50 {
  max-width: 100rem !important;
}
