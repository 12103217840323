$banner-height: 11.5rem;

.page {
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'header'
    'sidebar'
    'main';
  grid-template-rows: min-content min-content auto;
  grid-template-columns: auto;

  @media (min-width: $bp-lg) {
    grid-template-areas:
      'header header'
      'sidebar main';
    grid-template-rows: min-content auto;
    grid-template-columns: auto 1fr;
  }
}

.page__background {
  width: 100%;
  min-height: 100vh;
  padding: 4rem 2rem;
  background: $grey-10;
  text-align: center;
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
}

.main {
  grid-area: main;
}

.sidebar {
  background: $grey-10;
  @media (min-width: $bp-lg) {
    width: 15rem;
  }
  @media (min-width: $bp-xxl) {
    width: 16rem;
    // position: fixed;
    // top: header;
  }

  &__smsf-name {
    padding: 1.5rem;
    border-bottom: $border;
    font-weight: bold;
    line-height: 1.4rem;
    font-size: 15px;
  }

  &__links {
    margin-top: 1rem;

    li {
      margin-bottom: 0.25rem;
    }
  }

  a {
    display: block;
    margin-right: 1rem;
    padding: 12px 0.5rem 12px 1.5rem;
    border-radius: 0 1.5rem 1.5rem 0;
    color: $black;
    transition: all 0.5s ease-out;
    font-size: 15px;
    // font-weight: 600;

    &:hover,
    &.is-active {
      text-decoration: none;
      background: $grey-20;
    }
    &.is-active {
      background: $wrkr-yellow;
    }
  }
}

.main__no-sidebar {
  max-width: 1400px;
  margin: 0 auto;
}

.banner {
  // @include page-space;
  // margin-top: 2rem;

  padding: 2rem 2.5rem 2rem;

  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
    display: inline-block;
  }

  h2 {
    font-family: $display-font-family-regular;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 4px;
    strong {
      font-weight: 600;
    }
  }

  h3 {
    font-size: 14px;
  }

  &--fixed {
    border-bottom: $border;
    height: $banner-height;
    h1 {
      margin-bottom: 0.5rem;
    }
  }
}

.content {
  @include page-space;
  margin-bottom: 2.5rem;
}

// ------- TWO COLUMN IN MAIN CONTAINER-------//

.main__2col {
  min-height: calc(100vh - $banner-height - $header-height);

  display: grid;
  grid-template-areas:
    'content-area'
    'content-sidebar';
  grid-template-rows: auto;
  grid-template-columns: auto;

  @media (min-width: $bp-lg) {
    grid-template-areas: 'content-area content-sidebar';
    grid-template-rows: auto;
    grid-template-columns: 70% 30%;
  }

  .content-area {
    grid-area: content-area;
    padding: 2.5rem;
  }

  .content-sidebar {
    grid-area: content-sidebar;
    border-left: $border;
    padding: 2.5rem 1.5rem;
    // width: 20rem;
    // @media (min-width: $bp-xxl) {
    //   width: 30rem;
    // }
  }
}
