.member-details-accordion {
  margin-left: -2rem;
  margin-right: -2rem;
  .accordion {
    border-bottom: 1px solid $grey-10;

    div[aria-expanded='true'] {
      background: $grey;

      button {
        color: #fff;
      }
    }
  }

  .accordion__trigger {
    text-align: left;
    padding: 1rem 0.5rem;
    border-radius: 0;
  }

  .accordion__icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .accordion__inner {
    background-color: #fff;
  }

  .accordion__appendix {
    padding: 1rem 0.5rem;
    font-size: 0.875rem;
    float: right;
    text-decoration: underline;
    color: $grey;
  }

  .member-detail {
    font-size: 0.875rem;
    word-break: break-all;
    word-break: break-word;

    .row {
      padding: 0.5rem 0;
      border-bottom: 1px solid $grey-light;
    }

    // Last row in the accordion
    &:not(:empty):last-of-type .row:last-of-type {
      border-bottom: 0;
    }

    &__heading {
      background: $grey-10;

      .col-5 {
        color: $grey;
        font-weight: bold;
      }
    }
  }
}
