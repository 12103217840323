.geosuggest {
  position: relative;

  input {
    &:disabled {
      // opacity: 0.3;
      background-color: $grey-10;
      border: $grey-10;
    }
  }
}

.geosuggest__input-wrapper {
  height: 100%;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: white;
  border: 1px solid $grey-10;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  padding: 0.5rem 0.625rem;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $grey-10;
  }
}

.geosuggest__item--active {
  background: $blue;
  color: #fff;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
