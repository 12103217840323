.accordion {
  & + & {
    border-top: 1px solid $white;
  }
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  width: 100%;
  background: $grey-10;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: normal;
  transition: all 0.15s ease-in-out;

  &[aria-expanded="true"] {
    background: $black;
    color: $white;
  }
}

.accordion__title {
  display: inline-block;
  margin-bottom: 0;
  text-align: left;
}

.accordion__subtitle {
  @include border-radius;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: $alert;
  color: $white;
  font-weight: 700;
  text-align: center;
}

.accordion__panel {
  margin: 0;
  height: auto;
  overflow: hidden;
  // max-height: 62rem;
  background: $white;
  border: 1px solid $grey-50;
  border-top: 0;
  transition: max-height 0.25s ease-in-out;
}

.accordion__panel[aria-hidden="true"] {
  max-height: 0;
  border-color: $white;
}

.animateIn {
  animation: accordionIn 0.25s normal ease-in-out both;
}

@keyframes accordionIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes accordionOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.accordion__section {
  margin-bottom: 1.5rem;
}

.accordion__inner {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.1s linear, transform 0.1s ease;
  transition-delay: 0.35s;

  .accordion__panel[aria-hidden="false"] & {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;
    transition-delay: 0.1s;
  }
}
