.alert {
  @include border-radius;
  // width: 100%;
  // max-width: 38rem;
  margin: 0 auto 2rem;
  padding: 1rem 1rem 1.5rem;
  border: 1px solid transparent;
}

.alert--success {
  background: rgba($success, 0.1);
  border-color: $success;
}

.alert--info {
  background: rgba($info, 0.1);
  border-color: $info;
}

.alert--details {
  background: rgba($default, 0.1);
  border-color: $default;
}

.alert--alert {
  background: rgba($alert, 0.1);
  border-color: $alert;
}

.alert--notice {
  background: $notice;
  border-color: $notice;
}

.alert__container {
  display: flex;

  .alert__icon {
    flex-shrink: 0;
    margin-right: 1rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .alert__content {
    text-align: left;
    flex-grow: 1;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .alert__title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    // font-size: 1.125rem;
  }

  .alert__message {
    color: $black;

    small {
      color: $black;
    }

    ul {
      li {
        word-break: break-word;
        line-height: 1.5rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    dl {
      dt {
        width: 30%;
      }
      dd {
        width: 70%;
      }
    }
  }
}

.error--container {
  display: flex;
  // padding-top: 1rem; //CL commented this out to see if it affects anything
  padding-bottom: 0.5rem;
  &:last-child {
    padding-bottom: 0;
  }

  .error__tag {
    background: $alert;
    color: $white;
    font-size: 12px;
    padding: 2px 6px;
    @include border-radius(3px);
    margin-right: 1rem;
    display: inline-block;
    margin-top: 2px;
  }

  .error__title {
    margin-bottom: 0.2rem;
    font-weight: 700;
    font-size: 15px;
  }
  \ .error__content {
    color: $black;
    display: inline;
    font-size: 14px;

    small {
      color: $black;
    }
  }

  .error__refund {
    display: block;
    padding-top: 2px;
    font-size: 14px;
    color: $grey;
  }
}
.error--refund-total {
  border-top: 1px solid rgba($alert, 0.2);
  font-weight: bold;
  padding-top: 1rem;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 15px;
  button {
    margin-left: 1rem;
  }
}

tr.row-error {
  background-color: $grey-10;
  box-shadow: inset 0px 2px 2px 0px $grey-10;
  &:hover {
    background-color: $grey-10 !important;
  }
  .error--container {
    padding-top: 0;

    .error__refund {
      display: block;
      padding-top: 2px;
      font-size: 14px;
      color: $grey;
    }
  }
}

.main__no-sidebar {
  .alert {
    margin-top: 1rem;
    @include page-space;
  }
}
