.NewRollover {
  .fund-option {
    @include border-radius;
    background-color: $grey-10;
    padding: 1rem;
    margin: 1rem 0;

    div {
      margin-bottom: 0.5rem;
    }

    .fund-name {
      font-weight: 1.125rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .fund-no {
      font-size: 0.875rem;
      color: $grey;
      margin-bottom: 0.5rem;
    }
  }

  .member-details {
    @include border-radius;
    background-color: $grey-10;
    padding: 1rem;
    margin: 1rem 0;

    div {
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      color: $grey;
    }
  }
}
