.input-validation {
  @include border-radius;
  display: flex;
  padding: 1rem;
  margin-top: 0.75rem;
  background: $grey-10;
  // align-items: self-start;
  align-items: center;
}

.input-validation__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.input-validation__success {
  font-weight: 700;
  line-height: 1.5;
}
