.form-select-container {
  .form-select__input input {
    transition: none;

    &:focus,
    &:active {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
   
  }

  .form-select__control {
    background-color: $grey-05;
    border: 1px solid $grey-50;
    padding: 0.25rem 0;
    line-height: 1;
    color: $black;
    cursor: pointer;

    &:hover {
      border: 1px solid $grey-50;
    }
  }

  .form-select__control--is-focused {
    border-color: $grey-light;
    box-shadow: 0 0 0 1px $grey-light;
    outline: 0;
  }

  .form-select__single-value,
  .form-select__option {
    .is-light {
      opacity: 0.6;
    }
  }
  &.is-invalid {
    border: 1px solid $red;
  }
}

.form-select__menu {
  .form-select__option {
    .is-light {
      opacity: 0.6;
    }
  }
  .form-select__option--is-focused {
    background-color: $highlight;
    color: $black;
  }

  .form-select__option--is-selected {
    background-color: $highlight;
    color: $black;
  }
}
